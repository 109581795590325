import App from 'App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux-tk/store';
import { ThemeByContex } from 'Shared/Theme';

const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeByContex>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </ThemeByContex>
      </PersistGate>
    </Provider>
  );
};

export default Root;
