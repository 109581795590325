// navigation.ts
let navigateFunction: (path: string) => void;

export const setNavigate = (navigate: (path: string) => void): void => {
  navigateFunction = navigate;
};

export const navigateTo = (path: string): void => {
  if (navigateFunction) {
    navigateFunction(path);
  }
};
