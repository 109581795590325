import {
  Format,
  CustomImageAppearance,
  ImageMode,
  Placeholder,
  NoImage,
  Size,
  getPresetFromBreakpoint,
  srcSet,
  useCurrentBreakpoint,
  imageUrl,
  ImageBase,
} from 'utilities/breakpoints';
import { MediaProps } from './index';
import { Ratio } from 'Shared/AspectRatio';
import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';

export interface UrlViewModel {
  url: string;
  width: number;
  height: number;
  intrinsicSize: string;
}

export interface ImageUrlViewModel extends UrlViewModel {
  width: number;
  height: number;
  intrinsicSize: string;
}

export type ImagePropType = React.ImgHTMLAttributes<HTMLImageElement> & {
  myCustomSrc: UrlViewModel;
  preset?: Size;
  lazy?: boolean;
  format?: Format;
  mode?: ImageMode;
  padRatio?: Ratio;
  fastLoadImage?: boolean;
  shouldFill?: boolean;
  customImageAppearance?: CustomImageAppearance;
  onLoadSuccessful?: () => void;
};

export const ImagePreview: React.FC<MediaProps> = (props) => {
  const { src, name } = props;

  // Create an object of type UrlViewModel from the src string

  return (
    <ImageContainer>
      {/* <img src={src.url} alt="hej" /> */}
      <Image myCustomSrc={src} alt="hej" />
      <span>{name || 'Upload an image'}</span>
    </ImageContainer>
  );
};

type DefaultPropType = {
  ratio?: Ratio;
  preset?: Size;
  lazy?: boolean;
  fill?: boolean;
};

type PropType = DefaultPropType & ImagePropType;



const DENSITY_DESCRIPTORS = [1.5, 2];

export const Image: React.FC<PropType> = (props) => {
  const {
    myCustomSrc,
    preset,
    format,
    lazy = true,
    mode,
    padRatio,
    fastLoadImage,
    customImageAppearance,
    ...restProps
  } = props;

  const breakpoint = useCurrentBreakpoint();
  const width = (myCustomSrc as ImageUrlViewModel)?.width;

  // Memoize the internalPreset to prevent re-calculations
  const internalPreset = useMemo(() => {
    return preset || getPresetFromBreakpoint(breakpoint, width);
  }, [preset, breakpoint, width]);

  const [resolvedSrc, setResolvedSrc] = useState<string | null>(null);
  const [resolvedSrcSet, setResolvedSrcSet] = useState<string | null>(null);
  useEffect(() => {
    let isCancelled = false; // Prevent stale state updates
  
    const resolveImageUrls = async () => {
      try {
        // Resolve the primary image URL
        const resolvedUrl = await imageUrl(
          myCustomSrc.url,
          internalPreset,
          format,
          mode,
          width,
          customImageAppearance,
          padRatio
        );
  
        // Resolve srcSet using DENSITY_DESCRIPTORS
        const srcSetPromises = DENSITY_DESCRIPTORS.map(async (density) => {
          const densityWidth = width ? Math.round(width * density) : undefined;
          const densityUrl = await imageUrl(
            myCustomSrc.url,
            internalPreset,
            format,
            mode,
            densityWidth,
            customImageAppearance,
            padRatio,
            density
          );
          return densityUrl ? `${densityUrl} ${density}x` : null; // Ensure no null values
        });
  
        const resolvedSrcSetArray = await Promise.all(srcSetPromises);
        const resolvedSrcSet = resolvedSrcSetArray.filter(Boolean).join(', '); // Remove nulls
  
        if (!isCancelled) {
          setResolvedSrc(resolvedUrl);
          setResolvedSrcSet(resolvedSrcSet);
        }
      } catch (error) {
        console.error('Error resolving image URLs:', error);
      }
    };
  
    resolveImageUrls();
  
    return () => {
      isCancelled = true; // Clean up effect
    };
  }, [myCustomSrc.url, internalPreset, format, mode, width, customImageAppearance, padRatio]);
  
  if (!resolvedSrc) return <Placeholder src={NoImage} {...restProps} />;

  return (
    <img
      src={resolvedSrc}
      srcSet={resolvedSrcSet || undefined} // Set srcSet if resolved
      {...restProps}
    />
  );
};


const ImageContainer = styled.div`
  border 1px solid black;
  border-radius:20px;
`;

export default styled(Image);
