import ReactDOM from 'react-dom/client'; // Update this import
import { setDefaultLevel, LogLevelDesc } from 'loglevel';
import CONSTANTS from 'config/constants';
import Root from './Root';
import './i18n';
// import './index.scss';

setDefaultLevel(CONSTANTS.LOG_LEVEL as LogLevelDesc);

// Accessibility auditing. Do not enable in production.
// if (CONSTANTS.ENV !== 'production') {
//   // eslint-disable-next-line global-require, import/no-extraneous-dependencies
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
// }

const root = ReactDOM.createRoot(document.getElementById('root')!); // Use createRoot instead

root.render(<Root />);
