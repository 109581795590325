/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosRequestConfig } from 'axios';
import { UrlViewModel } from 'components/Media/Image';
import useAxios, { MethodTypes } from 'hooks/useAxios';
import { Image } from 'components/Media/Image';
import React, { useEffect, useState } from 'react';
import { backendUrl } from 'config/constants';
import axiosInstance from 'utilities/requestUtillity';

type TempProps = {
  url: string;
};

const ImageTemp: React.FC<TempProps> = (props) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    axiosInstance
      .get(props.url)
      .then((response) => {
        const base64Data = response.data.body; // The base64 string directly from the API response
        const contentType = response.headers['Content-Type']; // Correctly capitalized header
        const dataUri = `data:${contentType};base64,${base64Data}`;
        setImageSrc(dataUri);
      })
      .catch((error) => {
        console.error('Error fetching image', error);
      });
  }, [props.url]);

  return <div>{imageSrc && <img src={imageSrc} alt="Fetched from API" />}</div>;
};
export const AllImages: React.FC = () => {
  const [images, setImages] = useState<Array<UrlViewModel>>([]);
  const [callAxiosFn, axiosState] = useAxios<Array<UrlViewModel>>();

  useEffect(() => {
    const callingObj: AxiosRequestConfig = {
      method: MethodTypes.GET,
      url: `${backendUrl}images`,
    };
    if (!axiosState.data && !axiosState.loading && !axiosState.error) callAxiosFn(callingObj);
    // http://localhost:4000/api/
    //http://localhost:4000/api/images
    // fetch all images
    // set them to state
    // display them via the new Image comp
    // test smaller sizes.
  }, [callAxiosFn]);

  useEffect(() => {
    console.log('hejsan', axiosState.status);
    if (axiosState.data && !images.length) {
      setImages(axiosState.data);
    }
    // http://localhost:4000/api/
    //http://localhost:4000/api/images
    // fetch all images
    // set them to state
    // display them via the new Image comp
    // test smaller sizes.
  }, [axiosState, images]);

  return (
    <>{images.length > 0 && images.map((img, index) => <Image key={index} myCustomSrc={img} />)}</>
  );
};
