import { EnhancedStore as ToolkitStore } from '@reduxjs/toolkit';

import { switchBreakpoint } from 'redux-tk/slices/breakpointSlice';
import { useSelector } from 'react-redux';
import type { RootState } from 'redux-tk/store';
import styled from 'styled-components';
import { BrowserInfo } from 'browser';

export enum Breakpoint {
  Init = 0,
  /** Minimum window width alias for smaller smartphones in portrait mode */
  Micro = 1,
  /** Minimum window width alias for larger smartphones and smaller tablets in portrait mode */
  Tiny = 2,
  /** Minimum window width alias for larger tablets in portrait mode */
  Small = 3,
  /** Minimum window width alias for smaller tablets in landscape mode */
  Medium = 4,
  /** Minimum window width alias for larger tablets in landscape mode, smaller laptops and desktops */
  Large = 5,
  /** Minimum window width alias for larger laptops and desktops */
  Huge = 6,
  /** Minimum window width alias for very large laptops and desktops */
  Gigantic = 7,
}

const IMAGERESIZER_LIMIT = 3200;
const DENSITY_DESCRIPTORS = [1.5, 2];

export enum ImageMode {
  padding = 'pad',
  max = 'max',
}

export enum Format {
  JPG = 'jpg',
  GIF = 'gif',
  PNG = 'png',
}

type BREAKPOINT_SETTINGS = {
  BREAKPOINT_MICRO: number;
  BREAKPOINT_TINY: number;
  BREAKPOINT_SMALL: number;
  BREAKPOINT_MEDIUM: number;
  BREAKPOINT_LARGE: number;
  BREAKPOINT_HUGE: number;
  BREAKPOINT_GIGANTIC: number;
};

const breakpointSettings: BREAKPOINT_SETTINGS = {
  BREAKPOINT_MICRO: 320,
  BREAKPOINT_TINY: 412,
  BREAKPOINT_SMALL: 768,
  BREAKPOINT_MEDIUM: 1024,
  BREAKPOINT_LARGE: 1366,
  BREAKPOINT_HUGE: 1600,
  BREAKPOINT_GIGANTIC: 1920,
};

export enum Size {
  /** Image preset of 60px width */
  Thumb,
  /** Image preset of 320px width */
  Tiny,
  /** Image preset of 640px width */
  Small,
  /** Image preset of 960px width */
  Medium,
  /** Image preset of 1280px width */
  Large,
  /** Image preset of 1920px width */
  Huge,
  /** Image preset of 3200px width */
  Gigantic,
}

type SizesType = { [key: number]: number };

export enum CustomImageAppearance {
  Greybg = 'greybg',
  Background = 'background',
}

export const imageSizes: SizesType = {
  [Size.Thumb]: 160,
  [Size.Tiny]: 320,
  [Size.Small]: 640,
  [Size.Medium]: 960,
  [Size.Large]: 1280,
  [Size.Huge]: 1920,
  [Size.Gigantic]: 3200,
};

const breakpointKeys = Object.keys(breakpointSettings) as Array<keyof typeof breakpointSettings>;

export const mediaQueries = breakpointKeys.map((breakpoint) =>
  window.matchMedia(`(min-width: ${breakpointSettings[breakpoint]}px)`)
);

function getBreakpoint() {
  for (let i = mediaQueries.length; i > 0; i--) {
    if (mediaQueries[i - 1].matches) {
      return i;
    }
  }
  return 0;
}

export function useCurrentBreakpoint(): number {
  const state = useSelector((state: RootState) => state.currentBreakPoint);

  const update = getBreakpoint();

  if (state.currentBreakPoint === update) return state.currentBreakPoint;

  return update;
}

export function useIsCompact() {
  const breakpoint = useCurrentBreakpoint();
  return isCompact(breakpoint);
}

export function isCompact(breakpoint: Breakpoint) {
  return breakpoint <= Breakpoint.Small;
}

export function isTablet(breakpoint: Breakpoint) {
  return breakpoint === Breakpoint.Small;
}

export function useIsTablet() {
  const breakpoint = useCurrentBreakpoint();
  return isTablet(breakpoint);
}

export function setupResizeListener(store: ToolkitStore) {
  let willSwitch = false;
  const triggerSwitch = () => {
    if (!willSwitch) {
      willSwitch = true;
      requestAnimationFrame(() => {
        store.dispatch(switchBreakpoint(getBreakpoint()));
        willSwitch = false;
      });
    }
  };

  for (const mediaQuery of mediaQueries) {
    mediaQuery.addEventListener('change', triggerSwitch);
  }
}

export function getPresetFromWidth(
  estimatedWidth: number,
  availableSizes: SizesType = imageSizes
): Size {
  return parseInt(
    Object.keys(availableSizes).find((sizeKey) => availableSizes[sizeKey] >= estimatedWidth),
    10
  );
}

export function getPresetFromBreakpoint(breakpoint: Breakpoint, width?: number) {
  const maxWidthFromBreakpoint = widthFromBreakpointSafe(breakpoint + 1);

  if (typeof width === 'number' && maxWidthFromBreakpoint > width) {
    return null;
  }
  return getPresetFromWidth(maxWidthFromBreakpoint, imageSizes);
}

function widthFromBreakpointSafe(breakpoint: Breakpoint) {
  const breakpointKeys = Object.keys(Breakpoint)
    .map(Number)
    .filter((k) => typeof k === 'number' && !isNaN(k));
  const highestBreakpoint = breakpointKeys[breakpointKeys.length - 1];
  return widthFromBreakpoint(Math.min(breakpoint, highestBreakpoint));
}

export function widthFromBreakpoint(breakpoint: number): number {
  if (breakpoint === 0) {
    return 0;
  }

  const name = breakpointKeys[breakpoint - 1];

  if (name) {
    return breakpointSettings[name];
  }

  if (process.env.NODE_ENV !== 'production') {
    throw new Error('Invalid breakpoint: ' + breakpoint);
  } else {
    return breakpointSettings[breakpointKeys[breakpointKeys.length - 1]];
  }
}

function roundWithPrecision(num: number, precision: number) {
  return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function srcSet(
  src: string,
  preset?: Size,
  format?: Format,
  mode?: ImageMode,
  customImageAppearance?: CustomImageAppearance,
  width?: number
): string {
  return DENSITY_DESCRIPTORS.map((density) => {
    const url = imageUrl(
      src,
      preset,
      format,
      mode,
      width,
      customImageAppearance,
      undefined,
      density
    );
    if (url) {
      return `${url} ${density}x`;
    }
  })
    .filter((url) => !!url)
    .join();
}

// export function imageUrl(
//   src: string,
//   preset?: Size,
//   format?: Format,
//   mode?: ImageMode,
//   width?: number,
//   customImageAppearance?: CustomImageAppearance,
//   // eslint-disable-next-line @typescript-eslint/no-inferrable-types
//   padRatio: number = 1,
//   density = 1
// ): string {
//   if (src) {
//     const size = Math.round(imageSizes[preset] * density);
//     const appear = customImageAppearance;

//     if (size > IMAGERESIZER_LIMIT) {
//       return null;
//     }

//     const url = new URLX(src);

//     if (typeof preset === 'number') {
//       url.searchParams.set('w', String(size));

//       // Nitro has lowres images so we need to scale up images
//       // to project some kind of reality
//       // url.searchParams.set('scale', 'both');

//       if (mode) {
//         url.searchParams.set('mode', mode);

//         if (mode === ImageMode.padding) {
//           url.searchParams.set('h', String(Math.round(size * padRatio)));
//         }
//       }
//     }

//     url.searchParams.set('preset', 'background');

//     if (format) {
//       url.searchParams.set('format', format);
//     }

//     return relativeUrl(url);
//   }

//   return null;
// }


export async function imageUrl(
  src: string,
  preset?: Size,
  format?: Format,
  mode?: ImageMode,
  width?: number,
  customImageAppearance?: CustomImageAppearance,
  padRatio: number = 1,
  density = 1
): Promise<string | null> {
  // Calculate size based on preset and density
  const size = Math.round(imageSizes[preset] * density);

  // Early return if size exceeds the limit
  if (size > IMAGERESIZER_LIMIT) {
    return null;
  }

  // Construct the backend URL
  const url = new URL(src);
  if (typeof preset === 'number') {
    url.searchParams.set('w', String(size));
    if (mode) {
      url.searchParams.set('mode', mode);
      if (mode === ImageMode.padding) {
        url.searchParams.set('h', String(Math.round(size * padRatio)));
      }
    }
  }
  url.searchParams.set('preset', 'background');
  if (format) {
    url.searchParams.set('format', format);
  }

  try {
    // Fetch the presigned URL from the backend
    const response = await fetch(url.toString());
    if (!response.ok) {
      throw new Error('Failed to fetch presigned URL');
    }

    // Extract the presigned URL from the backend's response
    const { url: presignedUrl } = await response.json();
    return presignedUrl;
  } catch (error) {
    console.error('Error fetching presigned URL:', error);
    return null;
  }
}


export function isInternalUrl(url: HTMLAnchorElement | Location | URL | URLX) {
  if (!['http:', 'https:'].includes(url.protocol)) {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  if (url.host !== location.host) {
    return false;
  }
  return true;
}

export default class URLX {
  public hiddenParams = new URLSearchParams();
  private _url: URL;
  constructor(url: string, base?: string);
  constructor(url: string | URLX);
  // eslint-disable-next-line no-restricted-globals
  constructor(url: string | URLX, base = location.href) {
    // Wrapping native `URL` since TypeScript transpiles classes
    // with `super.call` instead of `new super` targeting ES5, which
    // causes `Uncaught TypeError: Failed to construct 'URL'` error.
    // May extend URL instead when classes wont be transpiled to ES5.
    if (typeof url === 'string') {
      this._url = new URL(url, base);
    } else if (url instanceof URLX) {
      // Cloning
      this._url = new URL(url.href);

      for (const entry of url.hiddenParams.entries()) {
        this.hiddenParams.append(entry[0], entry[1]);
      }
    } else {
      throw new Error(`Invalid URL: Expecting string or URLX but \`${url}\` was received`);
    }
  }

  // Native URL
  get hash(): string {
    return this._url.hash;
  }
  set hash(value: string) {
    this._url.hash = value;
  }
  get host(): string {
    return this._url.host;
  }
  set host(value: string) {
    this._url.host = value;
  }
  get hostname(): string {
    return this._url.hostname;
  }
  set hostname(value: string) {
    this._url.hostname = value;
  }
  get href(): string {
    return this._url.href;
  }
  set href(value: string) {
    this._url.href = value;
  }
  get origin(): string {
    return this._url.origin;
  }
  get password(): string {
    return this._url.password;
  }
  set password(value: string) {
    this._url.password = value;
  }
  get pathname(): string {
    return this._url.pathname;
  }
  set pathname(value: string) {
    this._url.pathname = value;
  }
  get port(): string {
    return this._url.port;
  }
  set port(value: string) {
    this._url.port = value;
  }
  get protocol(): string {
    return this._url.protocol;
  }
  set protocol(value: string) {
    this._url.protocol = value;
  }
  get search(): string {
    return this._url.search;
  }
  set search(value: string) {
    this._url.search = value;
  }
  get username(): string {
    return this._url.username;
  }
  set username(value: string) {
    this._url.username = value;
  }
  get searchParams(): URLSearchParams {
    return this._url.searchParams;
  }
  toString(): string {
    return this._url.toString();
  }

  // Custom URL
  get hiddenHref() {
    let hasHiddenParams = false;
    const params = new URLSearchParams(this.search);
    for (const entry of this.hiddenParams.entries()) {
      hasHiddenParams = true;
      params.append(entry[0], entry[1]);
    }

    if (!hasHiddenParams) {
      return this.href;
    }

    const hiddenUrl = new URL(this.href);
    hiddenUrl.search = params.toString();
    return hiddenUrl.href;
  }
}

export function relativeUrl(url: URLX, includeHash = true) {
  if (!isInternalUrl(url) || url.username || url.password) {
    return url.href;
  }

  const query = url.searchParams.toString();
  return url.pathname + (query ? `?${query}` : '') + (includeHash ? url.hash : '');
}

export function isImageUrl(url: UrlViewModel | ImageUrlViewModel): url is ImageUrlViewModel {
  return 'height' in url && 'width' in url;
}

export function calcRatio(src: UrlViewModel | ImageUrlViewModel, mode: ImageMode, preset: Size) {
  if (!(src && isImageUrl(src))) {
    return null;
  }
  const { width, height } = outputSize(src.width, src.height, mode, preset);
  return roundWithPrecision(height / width, 2);
}

export function outputSize(
  width: number,
  height: number,
  mode: ImageMode,
  preset: Size,
  padRatio = 1
) {
  if (typeof preset === 'number') {
    const ratio = height / width;
    width = mode === ImageMode.padding ? imageSizes[preset] : Math.min(width, imageSizes[preset]);
    height =
      mode === ImageMode.padding
        ? Math.round(imageSizes[preset] * padRatio)
        : Math.round(width * ratio);
  }
  return { width, height };
}

export interface UrlViewModel {
  url: string;
}

export interface ImageUrlViewModel extends UrlViewModel {
  width: number;
  height: number;
  intrinsicSize: string;
}

export interface ImageUrlViewModel extends UrlViewModel {
  width: number;
  height: number;
  intrinsicSize: string;
}

// Assuming transition is a function that generates the required CSS for transitions
const DEFAULT_DURATION = '300ms'; // Assuming a default duration
const DEFAULT_TIMING_FUNCTION = 'ease'; // Assuming a default timing function

export function transition(props) {
  const {
    property,
    duration = DEFAULT_DURATION,
    timingFunction = DEFAULT_TIMING_FUNCTION,
    delay,
    willChange,
  } = props;

  const properties = Array.isArray(property) ? property.join(', ') : property;

  // Constructing the CSS string
  let cssString = `transition: ${properties} ${duration} ${timingFunction}`;
  if (delay) cssString += ` ${delay}`;
  cssString += ';';

  if (willChange) {
    cssString += ` will-change: ${properties};`;
  }

  return cssString;
}

export const ImageBase = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
  ${transition({ property: 'opacity', duration: 300 })}
`;

export const NoImage = styled(ImageBase)``;

export const Placeholder = styled(ImageBase)`
  background-color: rgba(0, 0, 0, 0.05);
`;

const supports: typeof CSS.supports =
  typeof CSS !== 'undefined' && typeof CSS.supports === 'function'
    ? CSS.supports.bind(CSS)
    : () => false;

export function supportsObjectFit(browser: BrowserInfo) {
  const b = browser as any;
  return (
    !!b &&
    ((supports('object-fit', 'contain') && supports('object-position', 'center')) ||
      !(
        b.name === 'ie' ||
        (b.name === 'edge' && parseFloat(b.version) <= 15) ||
        (b.name === 'safari' && parseFloat(b.version) <= 9.2)
      ))
  );
}
